import React from 'react';

export default function About() {
    return (
        <>
        <h1 className="top_header">About</h1>
        <div className="body_of_text">
            <div id="who">
                <h3>Who We Are:</h3>
                <p>
                    We're a small business operating locally in Chicago and the surrounding suburbs. Serving as 
                    an independent contracting team, we take custom orders / jobs involving woodworking, stairs & railings,  
                    plasma cutting, and more. Additionally, we run a small online shop on Etsy. To reach us, 
                    navigate to the contact page and submit a form.
                </p>
            </div>

            <div id="services">
                <h3>Main List of Services:</h3>
                <ul>
                    <li>Custom Woodworking & Furniture</li>
                    <li>Stairs & Railings</li>
                    <li>Welding</li>
                    <li>Custom CNC Jobs - Wood & Metal</li>
                </ul>
            </div>

            <div id="links">
                <h3>Relevant Links:</h3>
                <ul>
                    <li><a href="https://www.etsy.com/shop/Yspish?ref=seller-platform-mcnav" target="_blank" rel="noreferrer">Etsy</a></li>
                    <li><a href="https://g.page/r/CTrVGHvb6WL5EAE/review" target="_blank" rel="noreferrer">Google Business Profile</a></li>
                </ul>
            </div>
        </div>
        </>
    )
} 