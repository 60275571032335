import React from 'react'
import {ImageGallery} from "react-image-grid-gallery";

// Update this when adding images
const maxImages = 31

// Randomize Order of Images in Gallery //
let t = [];
let order = [];
for (let i = 1; i <= maxImages; i++) {t.push(i);} 
while (t.length !== 0) {
  let idx = Math.floor(Math.random() * t.length);
  order.push(t[idx]);
  t = t.slice(0, idx).concat(t.slice(idx+1));
}

export default function Glry() {
  return (
    <>
      <h1 className="top_header">Gallery</h1>
      <div className="loader_container">
        <span class="loader"></span>
      </div>
      <Pictures/>
    </>
  )

  function Pictures() {
    const images = [];
    for (let i = 0; i < order.length; i++) {
      images.push({src: "imgs/" + order[i] + ".jpg"});
    }

    // makes sure all images load
    const smoothLoad = setInterval(() => {
      const all_images = document.querySelectorAll(`img[src*='imgs/']`);
      let loadedCount = 0;
    
      Array.from(all_images).forEach(image => {
        if (image.complete) loadedCount++;
      });

      if (loadedCount === maxImages) {
        document.querySelector(".loader").style.display = "none";
        document.querySelector(".imgs").style.opacity = 1;
        clearInterval(smoothLoad);
      }
    }, 100);

    return (
      <>
        <div class="imgs">
          <ImageGallery
            imagesInfoArray={images}
            columnCount="auto"
            columnWidth={350}
            gapSize={10}
          />
        </div>
      </>
    ); 
  }
} 
